<template>
    <v-container grid-list-xl class="elevation-2" color="red" rounded v-if="canEdit || (list && list.length > 0)">

        <h4>Enviar cópia para:</h4>
        <v-divider class="mb-4" />
        <v-layout wrap v-if="canEdit">
            <v-flex xs12 sm5>
                <v-text-field :label='$t("Nome")' v-model="item.name" v-on:keyup.enter="edit" />
            </v-flex>
            <v-flex xs12 sm5>
                <v-text-field type="email" v-model="item.email" :rules="[rules.email]" :label="$t('E-mail')" />
            </v-flex>
            <v-flex xs12 sm2 v-if="editMode">
                <v-btn color="warning" width="100%" small @click="edit">
                    <v-icon dark left small>edit</v-icon>
                    <template>{{ $t("Editar") }}</template>
                </v-btn>
            </v-flex>
            <v-flex xs12 sm2 v-else>
                <v-btn color="success" width="100%" small @click="add">
                    <v-icon dark left small>add</v-icon>
                    <template>{{ $t("Adicionar") }}</template>
                </v-btn>
            </v-flex>
        </v-layout>
        <v-simple-table fixed-header height="200px" v-if="list && list.length > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Nome
                        </th>
                        <th class="text-left">
                            Email
                        </th>
                        <th class="text-left" v-if="canEdit">
                            {{ $t("Ações") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="( i, index ) in list " :key="index">
                        <td>{{ i.name }}</td>
                        <td>{{ i.email }}</td>
                        <td v-if="canEdit">
                            <v-btn color="warning" fab @click="editItem(index)" x-small :disabled="editMode">
                                <v-icon>edit</v-icon>
                            </v-btn>
                            <v-btn color="error" class="ml-2" fab @click="deleteItem(index)" x-small :disabled="editMode">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>

<script>
import rules from "@/helpers/rules";

export default {
    props: {
        list: {
            type: Array,
            required: true,
        },
        canEdit: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            editMode: false,
            item: {
                name: null,
                email: null,
            },
        };
    },

    methods: {
        add() {
            if (this.item.name && this.item.email) {
                this.list.push({ ...this.item });
                this.item.name = null;
                this.item.email = null;
                return true;
            }
            return false;
        },
        edit() {
            if (this.add()) {
                this.editMode = false;
            }
        },
        deleteItem(i) {
            this.list.splice(i, 1);
            this.$eventHub.$emit("msgError", this.$t("Deletado com sucesso."));
        },
        editItem(i) {
            this.item = this.list[i];
            this.list.splice(i, 1);
            this.editMode = true;
        }
    },
};
</script>